import React from 'react'
import img2 from '../images/spdlogo.png'
import Eligibutton from './Eligibutton'
import '../css/Quote.css'

function Quote() {
    return (
        <div className='quotee' >

            <div className='qwrap'>

            <h1 className='ili1'><img className='iliimg' src={img2} ></img>  " Instead of living with discomfort, or frustration about your smile and bite... Please read on to learn how dental implants can help you! "<img className='iliimg' src={img2} ></img></h1>
            </div>
            <div className='qb'>

            <Eligibutton />
            </div>
        </div>
    )
}

export default Quote