import '../css/Eligibutton.css'

const Eligibutton = () => {
    const scrollToForm = () => {
        const formElement = document.getElementById('form'); // Assuming your Form component has an ID of "form"
        formElement.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className='eligible'>
        <button onClick={scrollToForm}>Check Your Eligibility</button>
        </div>
    )
}
export default Eligibutton