const questions = [
    {
        id: 1,
        question: "Which of the following statements aligns most closely with your dental situation?",
        options: [
            { id: "1a", text: "I have a single missing tooth.", nextQuestionId: 2 },
            { id: "1b", text: "I am missing multiple teeth.", nextQuestionId: 2 },
            { id: "1c", text: "I find it difficult to adapt to traditional dentures.", nextQuestionId: 2 },
            { id: "1d", text: "The majority of my teeth are in poor condition.", nextQuestionId: 2 }
        ],
        value:"ques"
        
    },

    {
        id: 2,
        question: "For dental services, our practice offers a number of payment options. We are happy to provide patients with an exclusive service that enables them to utilize their early Super release. In addition, we offer cost-effective payment plans, including one that costs $35 a week for a dental implant (total cost: $5,800) and one that costs $140-$145 per week for a single full arch (total cost: $28,000), with further possibilities within this price range. With these alternatives, you can get a new grin in just one to three days.",
        options: [
            { id: "2a", text: "I'm considering using my Super for treatment expenses.", nextQuestionId: 3 },
            { id: "2b", text: "I'm intrigued by the affordable payment plans you offer.", nextQuestionId: 4 },
            { id: "2c", text: "I can manage the expenses without requiring a payment plan.", nextQuestionId: 13 },
            { id: "2d", text: "The weekly cost range of $35-$145 exceeds my budget limits.", nextQuestionId: 3 }
        ],
        value:"ques"
    },
    {
        id: 3,
        question: "A significant portion of our patients finance this transformative procedure through their Super funds.Kindly indicate the option that aligns with your situation regarding Super funds:",
        options: [
            { id: "3a", text: "I possess $10k-$15k in my Super and wish to understand the process of utilizing these funds for my dental implant treatment.", nextQuestionId: 6 },
            { id: "3b", text: "I have $15k-$30k in my Super and am interested in learning more about accessing these funds for my dental implant treatment.", nextQuestionId: 6 },
            { id: "3c", text: "I hold $30k-$50k in my Super and would like to receive further information about accessing these funds for my dental implant treatment.", nextQuestionId: 6 },
            { id: "3d", text: "My Super holds less than $10k.", nextQuestionId: 7 }
        ],
        value:"ques"
    },
    {
        id: 4,
        question: "To determine if you qualify for payment plans, please answer the following: Are you a permanent resident of Australia?",
        options: [
            { id: "4a", text: "Yes", nextQuestionId: 5 },
            { id: "4b", text: "No", nextQuestionId: 7 }
        ],
        value:"ques"
    },
    {
        id: 5,
        question: "We'll be in touch to discuss implant treatment options and payment plans. Rest assured, this call is purely for information purposes, with no obligations on your part. If you decide to proceed and book a consultation, we're pleased to offer you a complimentary consultation with our Principal Dentist, along with a $500 discount voucher for your Dental Implants. Please let us know your preference: ",
        options: [
            { id: "5a", text: "Yes, I'm interested and would like to learn more about dental implants and payment plans.", nextQuestionId: 13 },
            { id: "5b", text: "No, I'm currently exploring options.", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 6,
        question: "We're committed to helping you make informed decisions. Expect a call to discuss implant treatment choices and payment plans, without any obligations attached. If, following our conversation, you decide to schedule a consultation, we're pleased to extend a complimentary session with our Principal Dentist. Plus, we'll offer you a $500 discount voucher for your Dental Implants. Please indicate your preference:",
        options: [
            { id: "6a", text: "Yes, I'm interested and would like to know more about dental implants and payment plans.", nextQuestionId: 13 },
            { id: "6b", text: "No, I'm currently in the exploration phase.", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 7,
        question: "It appears that utilizing Super for your dental treatment may not be possible. How do you intend to cover the procedure?      ",
        options: [
            { id: "7a", text: "Savings", nextQuestionId: 6 },
            { id: "7b", text: "Credit Card", nextQuestionId: 6 },
            { id: "7c", text: "Other loan (Home Equity, etc) ", nextQuestionId: 6 },
            { id: "7d", text: "Assistance from a relative ", nextQuestionId: 6 },
            { id: "7e", text: "No, I don't have an alternative method. ", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 8,
        question: "Do any of the following apply to you: Are you employed Full Time (at least 25 hours per week), an aged Pensioner (65+ years old), or a Veteran Affairs Pensioner?",
        options: [
            { id: "8a", text: "Yes", nextQuestionId: 6 },
            { id: "8b", text: "No", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 9,
        question: "Please indicate the option that most accurately describes you:",
        options: [
            { id: "9a", text: "Self-funded retirees ", nextQuestionId: 10 },
            { id: "9b", text: "Part-time employment less than 25 hours ", nextQuestionId: 11 },
            { id: "9c", text: "Carer’s pension / Widow’s pension / Disability pension ", nextQuestionId: 12 },
            { id: "9d", text: "None of the above ", nextQuestionId: 13 },
        ],
        value:"ques"
    },

    {
        id: 10,
        question: "If you identify as a self-funded retiree, could you affirm your ability to provide the following documents if requested: a recent utility bill, bank statements from the last 2 months, superannuation statement, and a copy of your Driver’s License, Aged Pension Card, or Veteran’s Card?        ",
        options: [
            { id: "10a", text: "Yes", nextQuestionId: 6 },
            { id: "10b", text: "No", nextQuestionId: 13 },

        ],
        value:"ques"
    },
    {
        id: 11,
        question: "If your part-time employment is less than 25 hours, could you verify your ability to furnish the following documents if required: a recent utility bill, bank statements from the last 2 months, two recent payslips, and a copy of your Driver’s License, Aged Pension Card, or Veteran’s Card?        ",
        options: [
            { id: "11a", text: "Yes", nextQuestionId: 6 },
            { id: "11b", text: "No", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 12,
        question: "If you're receiving a pension, could you please confirm your ability to provide the following documents if required: a recent utility bill, bank statements from the last 2 months, a recent Pension statement, and a copy of your Driver’s License, Aged Pension Card, or Veteran’s Card?",
        options: [
            { id: "12a", text: "Yes", nextQuestionId: 6 },
            { id: "12b", text: "No", nextQuestionId: 13 },
        ],
        value:"ques"
    },
    {
        id: 13,
        question: "Please provide your Full Name:",
        inputType: "text",
        nextQuestionId: 14,
        value:"name"
    },
    {
        id: 14,
        question: "Please provide your Phone Number:",
        inputType: "phone",
        nextQuestionId: 15,
        value:"number"
    },
    {
        id: 15,
        question: "SUBMITTED, We'll Contact You Soon",
        nextQuestionId: null
    },

    // {
    //     id: 16,
    //     question: "What is your Phone Number?",
    //     inputType: "phone",
    //     nextQuestionId: null
    // }
];

export default questions
