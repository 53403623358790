import './App.css';
import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Eligible from './components/Eligible';
import Form from './components/Form';
import If from './components/If';
import Quote from './components/Quote';
import Paying from './components/Paying';
import Implant from './components/Implant';
import Why from './components/Why';
import Offer from './components/Offer';
import Footer from './components/Footer';
import Header from './components/Header';
import Beforeafter from './components/Beforeafter';
import Info from './components/Info';

ReactPixel.init('1358422551548159');

function App() {
  return (
    <>
      <Header />
      <Form />
      <Info/>
      <Beforeafter/>
      <Eligible />
      <If />
      <Quote />
      <Paying />
      <Implant />
      <Why />
      <Offer />
      <Footer />
    </>

  );
}

export default App;
