import React from 'react'
import '../css/If.css'
import img1 from '../images/Framea6.png'


function If() {
    return (
        <div className='ifwrap'>
            <h1 className='ifh1' >If you come across any of the following challenges, know that we are here to support you with care.</h1>
            <div className='if'>
                <div className='ifcon'>
                    <h2>Are You Dealing With:</h2>
                    <ul className='iful' >
                        <li className='ifli' >Teeth that are missing, rotting, broken, or need replacement?</li>
                        <li className='ifli' >Frustration from wearing poorly fitted and uncomfortable dentures?</li>
                        <li className='ifli' >Pain when performing the simple act of chewing food?</li>
                        <li className='ifli' >Embarrassment or anxiety about smiling because of your teeth?</li>
                        <li className='ifli' >Feeling held back socially or professionally due to dental concerns?</li>
                        <li className='ifli' >Worries about the cost of dental procedures?</li>
                    </ul>
                </div>
                <div className='ifimg'>
                    <img src={img1} ></img>
                </div>
            </div>
                        <p className='ili'>If Yes, Dental Implants Are For You!</p>
                       

        </div>
    )
}

export default If