import React from 'react'
import '../css/Offer.css'

const Offer = () => {
  return (
    <div className='offerwrap'>
        <div className='offerr'>
            <h1>Claim Your Free Dental Implant Consultation, Exam, X-Rays AND $500 OFF Dental Implants.</h1>

        </div>
        
    </div>
  )
}

export default Offer