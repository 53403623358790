import React from 'react'
import img1 from '../images/img1.jpeg'
import img2 from '../images/img2.jpeg'
import img3 from '../images/img3.jpeg'
import img4 from '../images/img4.jpeg'
import img5 from '../images/img5.jpeg'
import img6 from '../images/img6.jpeg'
import img7 from '../images/img7.jpg'

import '../css/Beforeafter.css'

const Beforeafter = () => {
    return (
        <div className='wrapb' >
            <div className='afterheadings'>
                <div className='servicescontent' data-aos='fade-up'>
                    <p className='explore' style={{ color: '#644222', fontWeight: '1000' }}>
                        The Pleasure Of Beauty
                    </p>
                    <span className='theservices'>Before & After</span>
                    <p className='servicesdes'>
                        Are You Looking To Replace Missing Teeth, Eat Food With Ease, & Finally Be Confident In Your Smile Again?
                        We can help you! Here are some of our most recent smile transformations.
                    </p>
                </div>
            </div>
            <div className='beforegrid'>
                <div className='imag'>
                    <img src={img2}></img>
                </div>
                <div className='imag'>
                    <img src={img1}></img>
                </div>
                <div className='imag'>
                    <img src={img3}></img>
                </div>
                <div className='imag'>
                    <img src={img4}></img>
                </div>
                <div className='imag'>
                    <img src={img5}></img>
                </div>
                <div className='imag'>
                    <img src={img6}></img>
                </div>
                {/* <div className='imag'>
                    <img src={img7}></img>
                </div> */}
               
              
            </div>
        </div>
    )
}

export default Beforeafter