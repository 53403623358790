import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Eligible.css'


const Eligible = () => {

    useEffect(() => {
        AOS.init({ duration: 1000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
      }, []);




    const scrollToForm = () => {
        const formElement = document.getElementById('form'); // Assuming your Form component has an ID of "form"
        formElement.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <div className='eligi'  data-aos='fade-up' >

            <div className='eligible' >
                <h1>Ready For Your Transformation?</h1>
                <p>Complete the short online evaluation above to find out if you are eligible for dental implant treatment and which implant treatment would be best for you! </p>
                <button onClick={scrollToForm}>Check Your Eligibility</button>

            </div>
        </div>
    )
}

export default Eligible