import React from 'react'
import '../css/Paying.css'
import img1 from '../images/imagee(17).png'
import img2 from '../images/imagee(17).png'
import img3 from '../images/payl.png'
import Eligibutton from './Eligibutton'


const Paying = () => {
  return (
    <div className='pbg' >
      <div className='pgh1' >
        <h1>PAYING FOR DENTAL IMPLANTS IS EASIER THAN YOU THINK!</h1>
        <p className='pgp' >Here is what the Super process looks like...</p>
      </div>
      <div className='pcardwrap' >
        <div className='pgrid'>
          <div className='pcard'>
            <h1>Steps When Using Super To Pay For Treatment</h1>
            <img src={img1}></img>
            <ol className='pul'>
              <li className='pli'>You schedule a consultation to determine the most suitable treatment plan for your requirements. </li>
              
              <li className='pli'>Once you decide to proceed, our team initiates the process for you to access your Super Money for the treatment, fully paid and deposited into your bank account within 2-4 weeks.</li>
              <li className='pli'>You embark on your journey of transformation and treatment.</li>
            </ol>
          </div>
  
          <div className='pcard'>
            <h1>The Benefits Of Using Super To Pay For Treatment</h1>
            <img src={img1}></img>
            <ol className='pul'>
              <li className='pli'>This is your money put aside for your future wellbeing. A price can not be put on the increased quality of life that comes from new teeth.</li>
              <li className='pli'>Near 100% application success rate.</li>
              <li className='pli'>No debt incurred as these are your funds No cap on the amount released.</li>
              <li className='pli'>You can use a family member's Super if need be.</li>
            </ol>
          </div>
        </div>
        <div className='pcard1wrap'>

          <div className='pcard1'>

            <img src={img3}></img>
            <ol className='pul'>
              <li className='pli'>Up to $30k, Interest FREE*</li>
              <li className='pli'>No credit checks!*</li>
              <li className='pli'>A 10% deposit is required.</li>
              <li className='pli'>We can pre-approve on the spot if you have a drivers license and you are employed for a minimum of 25 hours per week.</li>
              <li className='pli'>Special approval process for patients who do not fit the above criteria.</li>
            </ol>
          </div>
        </div>
      </div>

      <Eligibutton/>
    </div>
  )
}

export default Paying
