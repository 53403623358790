import '../css/Footer.css'

const Footer = () => {
    const scrollToForm = () => {
        const formElement = document.getElementById('form'); // Assuming your Form component has an ID of "form"
        formElement.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
        <div className='eligiblebut'>
        <button onClick={scrollToForm}>Find Out If You're Eligible<br/>
Take the 60 Second Evaluation</button>
        <p>All Rights Reserved ⓒ 2023</p>
        </div>
        </>
    )
}
export default Footer