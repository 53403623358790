import React, { useState,useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import questions from '../components/Ques';
import '../css/Form.css';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { format } from 'date-fns';

const Form = () => {

  useEffect(() => {
    AOS.init({ duration: 3000,
        once: true, // Animations will only occur once
        mirror: true, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  useEffect(() => {
    ReactPixel.pageView(); // Track page view when ComponentA becomes visible
}, []);



  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [visitedQuestionIndices, setVisitedQuestionIndices] = useState([0]);
  const [inputValues, setInputValues] = useState({});
  const [phone, setPhone] = useState(''); // New state for phone number
  const [submitted, setSubmitted] = useState(false); // Added state to track submission
  const [formData, setFormData] = useState({});


  const handleOptionSelect = (optionId) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [currentQuestionIndex]: optionId,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [currentQuestion.id]: {
        optionId,
        inputValue: inputValues[currentQuestion.id] || '',
        phone,
      },
    }));
  };





  const handleSubmission = () => {
     const submissionTimestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setSubmitted(true);
    console.log(formData);

   const formDataToSend = {
      optionId: currentSelectedOption,
      inputValue: currentInputValue,
      phone: phone,
      submissionTime: submissionTimestamp, // Add the timestamp to the form data

    };


    axios.post('https://sheetdb.io/api/v1/xfrgva33zq7gc', formDataToSend)
      .then(response => {
        console.log('Form data sent successfully');
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
  }





  const handleInputValueChange = (inputQuestionId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [inputQuestionId]: value,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputQuestionId]: {
        optionId: selectedOptions[currentQuestionIndex] || '',
        inputValue: value,
        phone,
      },
    }));
  };


  const handleNextQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];

    if (currentQuestion.inputType === 'text' || currentQuestion.inputType === 'phone') {
      if (currentQuestion.nextQuestionId !== null) {
        const nextQuestionIndex = questions.findIndex(
          (question) => question.id === currentQuestion.nextQuestionId
        );
        setCurrentQuestionIndex(nextQuestionIndex);
        setVisitedQuestionIndices((prevIndices) => [
          ...new Set([...prevIndices, nextQuestionIndex]),
        ]);
      }
    } else {
      const selectedOption = currentQuestion.options.find(
        (option) => option.id === selectedOptions[currentQuestionIndex]
      );

      if (selectedOption && selectedOption.nextQuestionId !== null) {
        const nextQuestionIndex = questions.findIndex(
          (question) => question.id === selectedOption.nextQuestionId
        );
        setCurrentQuestionIndex(nextQuestionIndex);
        setVisitedQuestionIndices((prevIndices) => [
          ...new Set([...prevIndices, nextQuestionIndex]),
        ]);

      }

    }
    console.log(currentQuestionIndex);
  };

  const handlePreviousQuestion = () => {
    if (visitedQuestionIndices.length > 1) {
      const prevIndex = visitedQuestionIndices[visitedQuestionIndices.length - 2];
      const newVisitedQuestionIndices = visitedQuestionIndices.slice(
        0,
        visitedQuestionIndices.length - 1
      );
      setCurrentQuestionIndex(prevIndex);
      setVisitedQuestionIndices(newVisitedQuestionIndices);
    }
  };


  const handlePhoneChange = (value) => {
    setPhone(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [currentQuestion.id]: {
        optionId: currentSelectedOption,
        inputValue: currentInputValue,
        phone: value,
      },
    }));
  }

  const currentQuestion = questions[currentQuestionIndex];
  const currentSelectedOption = selectedOptions[currentQuestionIndex];
  const currentInputValue = inputValues[currentQuestion.id] || '';

 

  const handleSubmissionAndNext = () => {
    if (currentQuestionIndex === 13) {
      handleSubmission(); // Submit the form
      setSubmitted(true); // Mark the form as submitted

      // Wait for 2 seconds and then open another URL
      setTimeout(() => {
        window.location.href = 'https://thankyou.highlandsdentalimplant.com.au/'; // Replace with the actual URL you want to open
      }, 2000);
    } else {
      handleSubmission(); // Only submit the form without marking as submitted
      handleNextQuestion(); // Move to the next question
    }
  };


  return (
    <main className="formcontainer" id="form" data-aos='fade-up'>
      <h1>Access Your Super To Pay For Your Dental Implant Treatment.</h1>

      {currentQuestion && (
        <form className="formquestion">
          <h2 className="fquess" >{currentQuestion.question}</h2>
          <div className='formoption'>
            {currentQuestion.options &&
              currentQuestion.options.map((option) => (
                <label className='options' key={option.id}>
                  <input
                    type='radio'
                    name={`question-${currentQuestion.id}`}
                    value={option.id}
                    checked={currentSelectedOption === option.id}
                    onChange={() => handleOptionSelect(option.id)}
                  />
                  {option.text}
                </label>
              ))}
          </div>
          {currentQuestion.inputType === 'text' && (
            <div className='forminput'>
              <input
                type='text'
                value={currentInputValue}
                onChange={(e) =>
                  handleInputValueChange(currentQuestion.id, e.target.value)
                }
              />
            </div>
          )}
          {currentQuestion.inputType === 'phone' && (
            <div className='forminput'>
              <PhoneInput
                country={'au'}
                value={phone}
                onChange={handlePhoneChange}
              />
            </div>
          )}
        </form>
      )}



      <div className='formbuttons'>
        <button className="butt1"
          type="button"
          onClick={handlePreviousQuestion}
          disabled={visitedQuestionIndices.length <= 1}
        >
          ⇤ Previous
        </button>
        <button className="butt2"
          type={currentQuestionIndex === 14 ? "submit" : "button"}
          // onClick={
          //   currentQuestionIndex === 14 ? handleSubmission : handleNextQuestion
          // }



        onClick={handleSubmissionAndNext}


        disabled={
          currentQuestion.inputType === 'text' && currentInputValue === ''
        }
        >

        {currentQuestionIndex === 13 ? 'Submit' : 'Next ⇥'}



      </button>
    </div>
    </main >

  );
};

export default Form;
