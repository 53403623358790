import React from 'react';
import '../css/Header.css';
import img2 from '../gifs/OutgoingCall.gif';

const Header = () => {
  const phoneNumber = '+61449968018'; // Replace with the actual phone number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className='headbg'>
      <div className='headerr'>
        <div className='headlogo'>
          <p>Your local dental and implants center</p>
          <p>Multiple locations throughout Sydney</p>
        </div>
        <div className='headcontent'>
          <div className='headddp'>
            <img src={img2} style={{ height: '35px', width: '35px' }} alt="Call Icon" />
            <p className='headp'>
              <a href={`tel:${phoneNumber}`} onClick={handleCallClick}>
                {phoneNumber}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
      
