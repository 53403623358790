import React from 'react'
import img1 from '../images/teeth1.png'
import img3 from '../images/teeth2.png'
import img2 from '../images/teeth3.png'
import img4 from '../images/teeth4.png'
import '../css/Implant.css'

const Implant = () => {
    return (
        <div className='implantwrap'>
            <h1>We utilizes the latest dental implant procedures and technology.</h1>
            <div className='implantgrid'>
                <div className='implantcard' >
                    <img src={img1}></img>
                    <h1>Missing lots of teeth?</h1>
                    <p>Implant Stabilized Overdentures offer a cost-
                        effective alternative to regular dentures. These overdentures:</p>
                    <ul className='implantul' >
                        <li>● Snap into place over 2-4 implants</li>
                        <li>● Help prevent bone loss</li>
                        <li>● Vastly improve your food chewing and tasting</li>
                    </ul>
                </div>
                <div className='implantcard' >
                    <img src={img2}></img>
                    <h1>A Full Fixed Set Of Teeth</h1>
                    <p>This full, fixed arch implant uses the latest dental technology to give you a dream smile that’s as good as if not better than the smile nature gave you.</p>
                    <ul className='implantul' >
                        <li>● Is chip and stain resistant</li>
                        <li>● Offers a permanent solution (does not need to be removed)</li>
                        <li>● Is custom designed to fix onto 4-6 implants per arch</li>
                    </ul>
                </div>
                <div className='implantcard' >
                    <img src={img3}></img>
                    <h1>Missing just one tooth?</h1>
                    <p>A single dental implant crown is your best option.
                        It will:</p>
                    <ul className='implantul' >
                        <li>● Look and work like a natural tooth

                        </li>
                        <li>●  Restore your natural chewing ability</li>
                        <li>● Preserve your bone</li>
                        <li>● Save you money in the long run</li>
                    </ul>
                </div>
                <div className='implantcard' >
                    <img src={img4}></img>
                    <h1>Missing several teeth?</h1>
                    <p>A multi-implant bridge is ideal for filling medium-sized gaps in your smile. These bridges are:</p>
                    <ul className='implantul' >
                        <li>● Incredibly strong</li>
                        <li>● A permanent solution</li>
                        <li>● Great at preserving your bone</li>
                        <li>● A life-long natural teeth replacement solution</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Implant