import React, {useEffect} from 'react'
import '../css/Info.css'
import Eligibutton from './Eligibutton'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Info = () => {


    useEffect(() => {
        AOS.init({ duration: 3000,
            once: true, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
      }, []);
    



    return (
        <>
        <div className='infop' data-aos='fade-up'>

        <div className='infobg'>
        <div className='infowrap'>
            <h1>Finally A Solution That Doesn't Break The Bank!</h1>
            <h2>Comprehensive Dental Implant Consult</h2>
            <p>Get access to our "Pay with your Super" Done-For-You service.</p>
        </div>

            <ul class="custom-list">
                <li>FREE Exam, X-rays & Consultation (Valued at $350).</li>
                <li>Get a full diagnosis and treatment plan. Everything you need to make an informed decision on your future wellbeing.</li>
                <li>PLUS A $500 Discount* on the cost of your Dental Implants.</li>
            </ul>

            <h3>Find Out If You're Eligible</h3>
            <p>Take the 60 Second Evaluation.</p>
            <Eligibutton/>
        </div>
        </div>
        </>
    )
}

export default Info